html, body {
  height: 100%;
}

.container-fluid {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

#branding, #banner, div.announce, nav, footer {
  @extend .row;
}

// forces footer to stick to the bottom when page is less than window height
footer {
  margin-top: auto;
}