.announce {
  background-color: $light-blue;
  
  &>a, &>span, &>div {
    @extend .col;

    padding-top: .5rem;
    padding-bottom: .25rem;
  }

  a {
    text-decoration: none;
  }

  a, span {
    @extend h3;

    color: white;
    text-align: center;
    font-weight: bold;
    text-shadow: 1px 1px 3px $black;
  }
}