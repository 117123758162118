$breakpoint-xs: 0;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1600px;

$grid-breakpoints: (
  xs: $breakpoint-xs,
  sm: $breakpoint-sm,
  md: $breakpoint-md,
  lg: $breakpoint-lg,
  xl: $breakpoint-xl
);

$site-rooturl: "/";

@import "variables";
@import "bootstrap/scss/bootstrap";
@import "mixins";
@import "typography";
@import "global";
@import "carousel";
@import "layout";
@import "branding";
@import "navigation";
@import "breadcrumbs";
@import "footer";
@import "default";
@import "announce";
@import "blocks";
@import "home";
@import "locations";
@import "contact-form";
@import "products";
@import "case-studies";
@import "life-at-sonalysts";
