@import url(https://fonts.googleapis.com/css?family=Lato:400,300,300italic,400italic,700,700italic);

p, li, address {
  font-family: $body-font-family;
  color: $body-font-color;
  line-height: $body-line-height;
  font-size: $body-font-size;
  font-weight: $body-font-weight;
}

b, strong {
  font-weight: 600;
}

address {
  font-size: $contact-font-size;
  line-height: $contact-line-height;
  .p-name, .p-org {
    font-weight: $font-weight-heavy;
  }
}

.breadcrumb *, .block-sibling-nav ul * {
  font-family: $breadcrumb-font-family;
  font-size: $breadcrumb-font-size;
  font-weight: $breadcrumb-font-weight;
}

// loops the header font sizes map and
// outputs each value as font size
@each $name, $value in $header-font-sizes {
  #{$name} { font-size: #{$value}; }
}

h1, h2, h3, h4, h5, h6 {
  font-family: $header-font-family;
  font-weight: $header-font-weight;
  color: $header-color;
  a, a:link, a:hover, a:active, a:visited {
    color: $header-link-color;
  }
}

h1 {
  color: $primary;
}

h3 {
  font-weight: $font-weight-regular;
}

.subsection {
  h2 {
    border-bottom: solid 3px $dark-gray;
    line-height: 1.8;
    margin-bottom: 1rem;
  }
}

blockquote {
  margin: 0 2rem 1rem;
  padding: 0 2rem;
  border-left: solid $light-blue 5px;
}

.font-light {
  font-weight: $font-weight-light;
}

.font-regular {
  font-weight: $font-weight-regular;
}

.font-heavy {
  font-weight: $font-weight-heavy;
}

b {
  font-weight: $font-weight-heavy;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.justify {
  text-align: justify;
}

.hidden-sm {
  display: none;
}

.quote-card {
  border: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
  line-height: 1;
  padding: 1rem;
  margin-bottom: 1rem;
  max-width: 500px;
  blockquote,
  figcaption {
    border: none;
    margin: 0;
    padding: 0;
    p.quote-body,
    p.quote-attribution {
      text-align: left !important;
    }
  }
  blockquote {
    p {
      font-weight: $font-weight-heavy;
    }
    &::before {
      content: "“";
      color: $blue;
      font-size: 5rem;
      font-family:Georgia, 'Times New Roman', Times, serif;
      width: 100%;
      display: block;
      text-align: center;
    }
  }
}

.accolade {
  width: 100px;
  height: 100px;
  border-radius: 16px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
  padding: 1rem;
  .accolade-title,
  .accolade-date {
    text-align: center !important;
    font-family: $header-font;
    font-size: 1rem;
    font-weight: $font-weight-light;
  }
  .accolade-date {
    font-size: 1.5rem;
    color: $blue;
    font-weight: $font-weight-heavy;
  }
  .accolade-title::before {
    content: "⋆";
    color: $blue;
    font-size: 5rem;
    display: block;
    width: 100%;
    text-align: center;
    font-size: 5rem;
    line-height: 3rem;
  }
}

.figure-container {
  @media only screen and (min-width: $breakpoint-lg) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    figure {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      margin: 10px;
      flex-grow: 1;
      flex-basis: 0;
    }
  }
}
